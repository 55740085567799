import React, { lazy } from "react";
import { useState, useEffect } from "react"
import { EditorState, convertToRaw, convertFromRaw, ContentState, Modifier } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';

//import Editor from '@draft-js-plugins/editor';
// import createMentionPlugin, {
//   defaultSuggestionsFilter,
// } from '@draft-js-plugins/mention';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Config from '../../config';
import { useHistory, useParams } from "react-router-dom";
import { Question } from "./Question/Question";
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";
import mammoth from "mammoth";
import { GetDataFromContext } from "../../context/dataContext.js";


var editorRef = React.createRef();
const Header = lazy(() => import("../widgets/Header.js"));
const Asidebar = lazy(() => import('../widgets/Asidebar.js'))
const Footer = lazy(() => import('../widgets/Footer.js'))
const Jsfile = lazy(() => import('../widgets/Jsfile.js'))
const Popup = lazy(() => import('../widgets/DeletePop.js'))
const ViewLegalDocuments = () => {

  let { id } = useParams();
  let history = useHistory();

  const { questionAnswers} = GetDataFromContext()
  
  const [questions, setQuestions] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [allAttachedQuestionsData, setAllAttachedQuestionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [documentData, setDocumentData] = useState("");
  const [paragraphs, setParagraphs] = useState([]);
  const [documentQuestion, setDocumentQuestion] = useState([]);

  const data = [{ 'name': 'shivam' }, { 'name': 'adhesh' }];

  const _contentState = ContentState.createFromText('Sample content state');
  const raw = convertToRaw(_contentState);  // RawDraftContentState JSON
  const [contentState, setContentState] = useState(raw); // ContentState JSON




  const onEditorStateChange = (editorState) => {
    console.log("editorState", editorState);
    setEditorState(editorState)
    //return input.onChange(convertToRaw(editorState.getCurrentContent()))

    const contentState = editorState.getCurrentContent();

    const entities = [];
    contentState.getBlockMap().forEach(block => { // could also use .map() instead
      block.findEntityRanges(character => {
        const charEntity = character.getEntity();
        if (charEntity) { // could be `null`
          const contentEntity = contentState.getEntity(charEntity);
          entities.push(contentEntity);
        }
      });
    });

    const questionMapArray = [];
    entities.forEach((entity, i) => {
      //if (entity.get('type') === 'ANNOTATION') {
      const data = entity.get('data');
      if (data) {
        //console.log(questionsData)
        var questionMap = questionsData.find(c => c.url === data.url);
        if (questionMap) {
          questionMapArray.push(questionMap.url);
          // check question type is not free
          // if(questionMap.questionOptionId){
          //   questionMapArray.push(questionMap.questionOptionId);
          // }
        }
        // console.log(data.url)
      }
      // do something
      //}
    })

    setDocumentQuestion(questionMapArray)
    //console.log(questionMapArray)
  };


  useEffect(() => {

    const script = document.createElement('script');
    script.src = '/assets/js/main.js';   //(This is external js url)
    document.body.appendChild(script);
    // for layer dropdown

    getQuestions();
  }, [])

  const getQuestions = () => {
    setLoading(true);
    Config.get('/admin/get-questionnaire?_id=' + id)
      .then((res) => {

        setLoading(false);
        // get legal aggrement question
        //console.log(res.agreementData.agreement)
        setDocumentData(res.document)
        if (res.agreementData) {
          const contentState = convertFromRaw(JSON.parse(res.agreementData.agreement));
          //  const contentState = EditorState.createWithContent(rawContentFromStore);
          setEditorState(EditorState.createWithContent(contentState))
          //console.log(contentState)
          const entities = [];
          contentState.getBlockMap().forEach(block => { // could also use .map() instead
            block.findEntityRanges(character => {
              const charEntity = character.getEntity();
              if (charEntity) { // could be `null`
                const contentEntity = contentState.getEntity(charEntity);
                entities.push(contentEntity);
              }
            });
          });
          const questionMapArray = [];
          entities.forEach((entity, i) => {
            //if (entity.get('type') === 'ANNOTATION') {
            const data = entity.get('data');
            if (data) {
              //console.log(questionsData)
              // var questionMap = questionsData.find(c => c.url === data.url);
              // if(questionMap){
              //   questionMapArray.push(questionMap.url);
              //   // check question type is not free
              //   // if(questionMap.questionOptionId){
              //   //   questionMapArray.push(questionMap.questionOptionId);
              //   // }
              // }
              // console.log(data.url)
              questionMapArray.push(data.url);
            }
            // do something
            //}
          })
          //console.log(questionMapArray)
          setDocumentQuestion(questionMapArray)
        }
        if (res.data.length > 0) {
          var questionArray = [];

          // add payload from context
          if(Object.keys(questionAnswers) .length > 0){
            questionArray.push(questionAnswers)
        
          }

          var questionData = [];
          var questionCount = 1;
          res.data.forEach((items) => {
            // check option array
            var optionArray = [];
            var jumpToArray = [];
            var textDocumentOptionArray = [];
            if (items.Options) {
              items.Options.forEach((itemsOptions) => {
                optionArray.push(itemsOptions.optionValue)
                jumpToArray.push(itemsOptions.jumpToOption)
                textDocumentOptionArray.push(itemsOptions.textDocumentOption)
              })
            }
            questionArray.push({
              _id: items._id,
              is_optional: false,
              is_add_other: items.is_add_other,
              question: items.question,
              questionType: items.questionType,
              text_help: items.texHelp,
              is_logic: items.is_logic,
              text_document: items.text_document,
              options: optionArray,
              jumpTo: jumpToArray,
              textDocumentOption: textDocumentOptionArray
            })
            //console.log(items.Options)
            if (items.questionType !== 'pagebreak') {
              if (items.questionType === 'free') {
                questionData.push({
                  text: 'Q' + questionCount + ' ' + items.question,
                  value: 'Q' + questionCount + ' ' + items.question,
                  url: items._id,
                  type: 'free',
                  questionOptionId: ''
                })
                questionCount++;
              } else {
                // set suggestions
                // if(items.Options) {
                //   items.Options.forEach((itemsOptions,i) => {
                //     var OptionCount = i+1;
                //     questionData.push({
                //       text: 'Q' + questionCount + ' '+ items.question + ' (Option '+OptionCount+')',
                //       value: 'Q' + questionCount + ' '+ items.question +  ' (Option '+OptionCount+')',
                //       url: itemsOptions._id,
                //       type:items.questionType,
                //       questionOptionId:items._id
                //     })
                //   })
                //
                //   questionCount++;
                // }

                questionData.push({
                  text: 'Q' + questionCount + ' ' + items.question,
                  value: 'Q' + questionCount + ' ' + items.question,
                  url: items._id,
                  type: items.questionType,
                  questionOptionId: items._id
                })
                questionCount++;
              }
            }
          })
          setQuestions(questionArray);
          setQuestionsData(questionData);
        } else {
          // Config.showToast(res.message);
        }
      })
      .catch((err) => {
        console.log(err)
        Config.showToast("Please try after some time.");
      });
  }

  async function AddLegalAgreement(e) {
    e.preventDefault();

    //console.log(contentState)
    //console.log((convertToRaw(editorState.getCurrentContent())))
    // console.log(editorState.getCurrentContent().getPlainText())
    //console.log(editorState.getCurrentContent())

    //draftToHtml(convertToRaw(editorState.getCurrentContent()),{}, false,customEntityTransform)

    const contentState = editorState.getCurrentContent();

    const entities = [];
    contentState.getBlockMap().forEach(block => { // could also use .map() instead
      block.findEntityRanges(character => {
        const charEntity = character.getEntity();
        if (charEntity) { // could be `null`
          const contentEntity = contentState.getEntity(charEntity);
          entities.push(contentEntity);
        }
      });
    });

    const questionMapArray = [];
    entities.forEach((entity, i) => {
      //if (entity.get('type') === 'ANNOTATION') {
      const data = entity.get('data');
      if (data) {
        //console.log(questionsData)
        var questionMap = questionsData.find(c => c.url === data.url);
        if (questionMap) {
          questionMapArray.push(questionMap.url);
          // check question type is not free
          // if(questionMap.questionOptionId){
          //   questionMapArray.push(questionMap.questionOptionId);
          // }
        }
        // console.log(data.url)
      }
      // do something
      //}
    })



    //add-legal-agreement
    let data = Object.assign({});

    data["documentId"] = id;
    data["questionIdArray"] = [...new Set(questionMapArray)];
    data["agreement"] = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    Config.post('/admin/add-legal-agreement', data)
      .then((res) => {
        if (res.data) {
          //history.push("/legaldoc");
        } else {
          Config.showToast(res.message);
        }
      })
      .catch((err) => {
        Config.showToast("Please try after some time.");
      });


  }
  const getIndicesOf = (searchStr, str, caseSensitive) => {
    let tempStr = str;
    let tempSearchStr = searchStr;
    const searchStrLen = tempSearchStr.length + 1;
    if (searchStrLen === 0) {
      return [];
    }
    let startIndex = 0;
    let index;
    const indices = [];
    if (!caseSensitive) {
      tempStr = tempStr.toLowerCase();
      tempSearchStr = tempSearchStr.toLowerCase();
    }

    while ((index = tempStr.indexOf(tempSearchStr, startIndex)) > -1) {
      indices.push(index - 1);
      startIndex = index + searchStrLen;
    }
    return indices;
  };

  const getEntityRanges = (text, mentionName, mentionKey) => {
    const indices = getIndicesOf(mentionName, text);
    if (indices.length > 0) {
      return indices.map(offset => ({
        key: mentionKey,
        length: mentionName.length + 1,
        offset
      }));
    }
    return null;
  };

  const createMentionEntities = (text, html, tags) => {
    console.log(tags);
    const blockMap = stateFromHTML(html).blockMap
    const newState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), blockMap)
    const rawContent = convertToRaw(newState);
    const rawState = tags.map(tag => ({
      type: 'MENTION',
      mutability: 'IMMUTABLE',
      data: tag
    }));

    rawContent.entityMap = [...rawState];
    rawContent.blocks = rawContent.blocks.map(block => {
      const ranges = [];
      tags.forEach((tag, index) => {
        const entityRanges = getEntityRanges(block.text, tag.text, index);
        if (entityRanges) {
          ranges.push(...entityRanges);
        }
      });
      console.log(block);
      return { ...block, entityRanges: ranges };
    });
    console.log(rawContent);
    return convertFromRaw(rawContent);
  };
  const handlePastedText = (text, html, editorState, onChange) => {
    console.log("text", text);
    console.log("html", html);
    setEditorState(EditorState.createWithContent(createMentionEntities(text, html, questionsData)));
    return true;
  };
  const handleFileUpload = (e) => {
    const reader = new FileReader();
    let file = e.target.files[0];
    reader.onload = async (e) => {
      const content = e.target.result;
      try {
        const result = await mammoth.extractRawText({ arrayBuffer: content });
        const paragraphs = result.value; // Extracted paragraphs
        console.log("Extracted content:", paragraphs);
        const { value: html } = await mammoth.convertToHtml({ arrayBuffer: content });
        console.log("Converted to HTML:", html);
        setEditorState(EditorState.createWithContent(createMentionEntities(paragraphs, html, questionsData)));
      } catch (error) {
        console.error("Error extracting content:", error);
      }
    };
    reader.onerror = (err) => console.error(err);
    reader.readAsBinaryString(file);
  };
  return (
    <>

      <Header />
      <Asidebar />
      <main id="main" className="main">

        <Popup />
        <section className="section">
          <div id="imgLoader" style={{ display: "none" }} className="lds-spinner"><div></div><div>
          </div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">

                {loading ?
                  <span className={"go-back d-flex"} style={{ height: '26px;' }}>
                  </span>
                  :
                  <span className={"go-back d-flex"} onClick={() => history.goBack()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15 8C15 7.72386 14.7761 7.5 14.5 7.5L2.70711 7.5L5.85355 4.35355C6.04882 4.15829 6.04882 3.84171 5.85355 3.64645C5.65829 3.45118 5.34171 3.45118 5.14645 3.64645L1.14645 7.64645C0.951184 7.84171 0.951184 8.15829 1.14645 8.35355L5.14645 12.3536C5.34171 12.5488 5.65829 12.5488 5.85355 12.3536C6.04882 12.1583 6.04882 11.8417 5.85355 11.6464L2.70711 8.5H14.5C14.7761 8.5 15 8.27614 15 8Z"
                        fill="black" class="pointer" />
                    </svg>
                    <div>{(documentData) ? documentData.name : ''} </div>
                  </span>
                }

                <ul className="nav nav-tabs nav-tabs-bordered align-items-center">
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      onClick={() => { history.push(`/legal-document/${id}/information`) }}
                    >
                      Information
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link "
                      onClick={() => { history.push(`/legal-document/${id}/questionnaire`) }}
                    >
                      Questionnaire
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link active"
                      onClick={() => { history.push(`/legal-document/${id}/document`) }}
                    >
                      Document
                    </button>
                  </li>
                  <li className="nav-item edit_div img_left_profile ">

                    <input type="file" accept=".docx" onChange={handleFileUpload}
                      className="img_left_profile_upload" />
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="upload-btn"
                    >
                      <path
                        d="M4.24968 14.7347C3.86009 14.7347 3.52647 14.5959 3.2488 14.3182C2.97113 14.0405 2.83254 13.7071 2.83301 13.318V11.193H4.24968V13.318H12.7497V11.193H14.1663V13.318C14.1663 13.7076 14.0275 14.0412 13.7498 14.3189C13.4722 14.5966 13.1388 14.7352 12.7497 14.7347H4.24968ZM7.79134 11.9014V6.12845L5.94968 7.97012L4.95801 6.94303L8.49968 3.40137L12.0413 6.94303L11.0497 7.97012L9.20801 6.12845V11.9014H7.79134Z"
                        fill="url(#paint0_linear_7384_10460)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_7384_10460"
                          x1="8.49967"
                          y1="3.40137"
                          x2="8.49967"
                          y2="14.7347"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF17B3" />
                          <stop offset="1" stop-color="#DA189B" />
                        </linearGradient>
                      </defs>
                    </svg>{" "}
                    <span
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "900",
                      }}
                    >
                      Upload Document
                    </span>
                  </li>
                </ul>

                {loading ?
                  <div className="card-body">
                    <div className={"loading-icon"}>
                      <center>
                        <img src="/assets/img/loading-icon.gif" alt="img" />
                      </center>
                    </div>
                  </div>
                  :
                  <div className="card-body">
                    <form className="row g-3" method='post' onSubmit={AddLegalAgreement}>
                      {/*<h5 className="card-title"> {(documentData)?documentData.name:''} </h5>*/}

                      <div className="">
                        <Editor
                          ref={editorRef}
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                            fontFamily: {
                              options: ["Calibri", "Georgia", "Impact", "Tahoma", "Verdana", "Open Sans"],
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                            },
                          }}
                          //initialContentState={contentState}
                          onEditorStateChange={onEditorStateChange}
                          handlePastedText={handlePastedText}
                          mention={{
                            separator: ' ',
                            trigger: '@',
                            suggestions: questionsData,
                            // suggestions: [
                            //   { text: 'APPLE', value: 'apple', url: 'apple adas' },
                            //   { text: 'BANANA', value: 'banana', url: 'banana ad' },
                            //   { text: 'CHERRY', value: 'cherry', url: 'cherry asd' },
                            //   { text: 'DURIAN', value: 'durian', url: 'durian asd' },
                            //   { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit asds' },
                            //   { text: 'FIG', value: 'fig', url: 'fig asdsa' },
                            //   { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit asd' },
                            //   { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew asd' },
                            // ]
                          }}

                          editorStyle={{ border: "1px solid #C0C0C0", minHeight: '250px' }}
                          hashtag={{}}
                        //defaultEditorState={editorState}
                        //defaultContentState={contentState}
                        //onContentStateChange={setContentState}
                        />

                        <div className={"mt-20"}>
                          <button type="submit" className="btn submit-btn1">
                            Save
                          </button>
                        </div>
                      </div>

                      {/* <div className="col-md-3 question-data">

                        {questions.map((question, index) => (
                          <>
                            {question.questionType !== 'pagebreak' &&
                              <Question
                                key={index}
                                index={index}
                                questionNo={questions.filter((arr, questionLength) => {
                                  if (arr.questionType !== 'pagebreak' && questionLength <= index) {
                                    return true;
                                  }
                                  return false;
                                }).length}
                                question={question}
                                isAction={false}
                                documentQuestion={documentQuestion}
                              //onCopy={()=>duplicatequestion(index)}
                              //onDelete={() => removequestion(index)}
                              //onEdit={() => edittingQuestionsHandler(index)}
                              />
                            }
                          </>
                        ))}
                      </div> */}


                    </form>

                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />


    </>
  );
};

export default ViewLegalDocuments;
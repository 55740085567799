import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Config from "../../../config";
import Options from "./Options";

import { EditorState,convertToRaw,convertFromRaw,ContentState } from 'draft-js';
import { GetDataFromContext } from "../../../context/dataContext";



export const QuestionInput = ({parentFunction, questionType, cancelQuestion, addQuestions, language, questionno, questionlist, changeQuestionType, islogic }) => {

  const { setIsAddOther} = GetDataFromContext()

  const [questionFormValues, setQuestionFormValues] = useState({
    question: "",
    text_help:"",
    text_document:"",
    is_optional: false,
    is_logic: false,
    logic_option:"End survey",
  });
  const [questionFormErrors, setQuestionFormErrors] = useState({
    question: null,
    text_help:null,
    text_document:null,
    error_class: null,
  });
  const [allOptions, setAllOptions] = useState([""]);
  const [tempAllOptions, setTempAllOptions] = useState([""]);
  const [allJumpTo, setAllJumpTo] = useState([]);
  const [allTextDocument, setAllTextDocument] = useState([]);
  const [addingOptions, setAddingOptions] = useState(false);
  const [unsavedOptions, setUnsavedOptions] = useState([""]);
  const [optionError, setOptionError] = useState("");
  const [optionErrorIndex, setOptionErrorIndex] = useState(null);
  const [otherOptionIncluded, setOtherOptionIncluded] = useState(false);
  const [ratingScale, setRatingScale] = useState("5");
  const [likertSubQuestions, setLikertSubQuestions] = useState([""]);
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [logicAdded, setLogicAdded] = useState(false);
  const [jumpToListItems, setJumpToListItems] = useState([]);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isOtherOption, setIsOtherOption] = useState(false);

  const [likertScaleLength, setLikertScaleLength] = useState("5 points");
  const [likertScaleType, setLikertScaleType] = useState("Satisfaction");
  //   const questionTypes=["free","singlechoice", "multiplechoice","ratingscale","likertscale","rankingscale"];
  //const questionTypes = [{ label: "Free text", value: "free" }, { label: "Single choice", value: "singlechoice" }, { label: "Multiple choice", value: "multiplechoice" }, { label: "Rating scale", value: "ratingscale" }, { label: "Likert scale", value: "likertscale" }, { label: "Ranking scale", value: "rankingscale" }];
  const questionTypes = islogic?[{ label: "Free text", value: "free" }, { label: "Single choice", value: "singlechoice" }, { label: "Multiple choice", value: "multiplechoice" }, { label: "Likert scale", value: "likertscale" }]
    :[{ label: "Free text", value: "free" }, { label: "Single choice", value: "singlechoice" }, { label: "Multiple choice", value: "multiplechoice" }, { label: "Likert scale", value: "likertscale" }];

  const [questionsData, setQuestionsData] = useState([]);

  const setQuestion = (name) => {
    return ({ target: { value } }) => {
      setQuestionFormValues((oldValues) => ({ ...oldValues, [name]: value }));

      if(name === 'text_help'){
        if (value.length > 0) {
          setQuestionFormErrors({...questionFormErrors, text_help: null, error_class: null });
        }
      }else if(name === 'text_document'){
        if (value.length > 0) {
          setQuestionFormErrors({...questionFormErrors, text_document: null, error_class: null });
        }
      }else {
        if (value.length > 0) {
          setQuestionFormErrors({question: null, error_class: null});
        }
      }
    };
  };
  const showError = (error) => {

    Config.showToast(error);
  }

  const closeOptionHandler = (index) => {
    const list = [...allOptions];
    list.splice(index, 1);
    setAllOptions(list);

    // set text document blank
    const document = [...allTextDocument];
    document.splice(index, 1);
    setAllTextDocument(document);
  };
  const closeOtherOptionHandler = () => {
    const list = [...allOptions];
    list.pop();
    setAllOptions(list);
    setOtherOptionIncluded(false);
  };
  const saveOptionHandler = (index, value) => {
    allOptions.splice(index, 1, value);
    setAllOptions([...allOptions]); 
  };
  const saveAllTextDocument = (index, value) => {
    allTextDocument.splice(index, 1, value);

    //console.log(JSON.stringify(convertToRaw(value.getCurrentContent())))
    //console.log(value)

    setAllTextDocument([...allTextDocument]);
  };

  const saveOptionJumpToHandler = (index, value) => {
    allJumpTo.splice(index, 1, value);
    setAllJumpTo([...allJumpTo]);
  };
  const addSubQuestionHandler = () => {
    likertSubQuestions.push("");
    setLikertSubQuestions([...likertSubQuestions]);
  };
  const removeSubQuestionHandler = (index) => {
    if (likertSubQuestions.length == 1) {
      showError(
        "Minimum one option is required."
      );
    } else {
      const list = [...likertSubQuestions];
      list.splice(index, 1);
      setLikertSubQuestions(list);
    }
  };
  const editOptionHandler = (index) => {
    const list = [...allOptions];
    const optionToBeEdited = list[index];
    list.splice(index, 1);
    setAllOptions(list);
    const listUnsavedOptions = [...unsavedOptions];
    listUnsavedOptions.splice(0, 0, optionToBeEdited);
    setUnsavedOptions(listUnsavedOptions);
  };
  const showQuestionFormError = (name, value) => {
    let formErrorsLocal = Object.assign({}, questionFormErrors);
    for (var key in questionFormErrors) {
      formErrorsLocal[key] = null;
    }
    formErrorsLocal[name] = value;
    formErrorsLocal["error_class"] = "input_error";
    setQuestionFormErrors(formErrorsLocal);
  };
  const handleQuestionSubmit = () => {
    setOptionErrorIndex(null);
    if (questionFormValues.question === "") {
      showQuestionFormError("question", "Required field");
      document.getElementById("question").scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    // else if (questionFormValues.text_help === "") {
    //   showQuestionFormError("text_help", "Required field");
    //   document.getElementById("question").scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //   });
    // }
    else {

      if (questionType === "free") {

        // if(questionFormValues.text_document === ""){
        //   showQuestionFormError("text_document", "Required field");
        //   document.getElementById("question").scrollIntoView({
        //     behavior: "smooth",
        //     block: "center",
        //   });
        // }else{
        if (addQuestions) {
          addQuestions({
            _id:"",
            questionType: questionType,
            question: questionFormValues.question,
            text_help: questionFormValues.text_help,
            //text_document: questionFormValues.text_document,
            text_document: '',
            textDocumentOption:[],
            is_optional: questionFormValues.is_optional,
            is_logic: questionFormValues.is_logic,
            logic_option: questionFormValues.logic_option,
            optionsId:[]
          });
          setQuestionFormValues({
            question: "",
            text_help: "",
            text_document: "",
            is_optional: true,
          });
        }
        // }

      }
      if (questionType === "singlechoice") {

        let nulloptioncount = 0;
        allOptions.forEach((element,index) => {
          if (element !== "") {
            nulloptioncount = nulloptioncount + 1;
          }
        });

        let nullTextDocumentcount = 0;
        allTextDocument.forEach((element,index) => {
          if (element !== "") {
            nullTextDocumentcount = nullTextDocumentcount + 1;
          }
        });

        const list = [...allOptions];
        for(var i=0; i<list.length;i++ )
        {
          if(list[i]=="" || list[i]==" "){
            list.splice(i,1);
            i--;
          }
        }

        if (nulloptioncount !== 0) {
          // if (allOptions.length < 2) {
          if (nulloptioncount < 2) {
            showError(
              "Single choice questions require atleast two options."
            );
          } else {
            if(nullTextDocumentcount !== nulloptioncount){
              showError(
                "Please fill all the text go in document option."
              );
              return false;
            }
            if (addQuestions) {
              addQuestions({
                _id:"",
                questionType: questionType,
                question: questionFormValues.question,
                text_help: questionFormValues.text_help,
                is_optional: questionFormValues.is_optional,
                is_logic: questionFormValues.is_logic,
                logic_option: questionFormValues.logic_option,
                options: list,
                jumpTo: allJumpTo,
                textDocumentOption: allTextDocument,
                text_document:'',
                optionsId:[]
              });
              setQuestionFormValues({
                question: "",
                text_help: "",
                is_optional: true,
              });
              setAllOptions([]);
              setAllTextDocument([]);
              setOtherOptionIncluded(false);
            }

          }
        } else {
          showError("Your answer is empty - please enter option.");
        }

      }
      if (questionType === "multiplechoice") {

        let nulloptioncount = 0;
        allOptions.forEach((element) => {
          if (element !== "") {
            nulloptioncount = nulloptioncount + 1;
          }
        });

        let nullTextDocumentcount = 0;
        allTextDocument.forEach((element,index) => {
          if (element !== "") {
            nullTextDocumentcount = nullTextDocumentcount + 1;
          }
        });
        const list = [...allOptions];
        for(var i=0; i<list.length;i++ )
        {
          if(list[i]=="" || list[i]==" "){
            list.splice(i,1);
            i--;
          }
        }
        if (nulloptioncount !== 0) {
          if (nulloptioncount < 2) {
            showError("Multi choice questions require atleast two options.");
          } else {

            if(nullTextDocumentcount !== nulloptioncount){
              showError(
                "Please fill all the text go in document option."
              );
              return false;
            }

            if (addQuestions) {
              addQuestions({
                _id:"",
                questionType: questionType,
                question: questionFormValues.question,
                text_help: questionFormValues.text_help,
                is_optional: questionFormValues.is_optional,
                is_logic: questionFormValues.is_logic,
                logic_option: questionFormValues.logic_option,
                options: list,
                textDocumentOption: allTextDocument,
                text_document:'',
                isOtherText: questionFormValues.isOtherText,
                optionsId:[]
              });
              setQuestionFormValues({
                question: "",
                text_help: "",
                is_optional: true,
              });
              setAllOptions([]);
              setAllTextDocument([]);
              setOtherOptionIncluded(false);
            }
          }
        } else {
          showError("Your answer is empty - please enter option.");
        }
      }

    }
  };
  const handleDragStart = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);
    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);
    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };
  const handleDragUpdate = (event) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);
    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;
    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);
    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];
    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);
    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };
  const getDraggedDom = (draggableId) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);
    return draggedDOM;
  };
  useEffect(() => {
    var list = [];
    for (var i = questionno + 1; i <= questionlist.filter(arr => {if (arr.questionType !== 'pagebreak') {return true;}}).length; i++) {
      list.push(i);
    }
    setJumpToListItems(list);
    var jumptolist = [];
    for (var i = 0; i < allOptions.length; i++) {
      jumptolist.push("End Survey");
      //jumptolist.push(questionno < questionlist.filter(arr => {if (arr.questionType !== 'pagebreak') {return true;}}).length ? questionno + 1 : "End Survey");
    }
    setAllJumpTo(jumptolist);

    // set items to logioc options
    var dropdownOptionsArray = [];
    jumptolist.forEach((item, i) => (
      <>
        {item == "End Survey" ?
          dropdownOptionsArray.push({ label: "End survey", value: "End Survey" })
          :
          (item - questionno == 1)?
            dropdownOptionsArray.push({ label: "Next Question", value: item })
            :
            dropdownOptionsArray.push({ label: "Question "+item, value: item })
        }
      </>
    ));
    // dropdownOptionsArray.push({ label: "End survey", value: "End Survey" });
    setDropdownOptions(dropdownOptionsArray);

    // set data for mention suggestion
    var questionData = [];
    var questionCount = 1;
    questionlist.forEach((items)=>{

      if (items.questionType !== 'pagebreak') {

        if(items.questionType === 'free') {
          questionData.push({
            text: 'Q' + questionCount + ' '+ items.question,
            value: 'Q' + questionCount + ' '+ items.question,
            url: items._id,
            type:'free',
            questionOptionId:''
          })
          questionCount++;
        }else{

          // // set suggestions
          // if(items.optionsId) {
          //   items.optionsId.forEach((itemsOptions,i) => {
          //     //console.log(itemsOptions)
          //     var OptionCount = i+1;
          //     questionData.push({
          //       text: 'Q' + questionCount + ' '+ items.question + ' (Option '+OptionCount+')',
          //       value: 'Q' + questionCount + ' '+ items.question +  ' (Option '+OptionCount+')',
          //       url: itemsOptions,
          //       type:items.questionType,
          //       questionOptionId:items._id
          //     })
          //   })
          //
          //   questionCount++;
          // }
          questionData.push({
            text: 'Q' + questionCount + ' '+ items.question,
            value: 'Q' + questionCount + ' '+ items.question,
            url: items._id,
            type:items.questionType,
            questionOptionId:items._id
          })
          questionCount++;
        }
      }



    })
    setQuestionsData(questionData);

  }, []);
  return (
    <>
      {questionType == "free" && (
        <div className={`add-question-wrapper questioninputbox`}>
          <div className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap`}>
            <div className="bd-que-slide-left">
              <div className="db-que-list-wrap survey-question-drop-down">
                <div className="dropdown">
                  Question {questionno} - Free text
                </div>
              </div>
            </div>
            <div className="bd-que-slide-arrow">
              <div className="sqa-check-top">
                <div className="d-flex form-group custom-control custom-checkbox mb-0 dark-checkbox">

                  <div className="form-group custom-control mb-0 logicbuttonbox">
                    <label className="switch">
                      <input type="checkbox"
                             checked={Boolean(questionFormValues.is_logic)}
                             onChange={(e) => {
                               setQuestionFormValues({
                                 ...questionFormValues,
                                 is_logic: e.target.checked ? 1 : 0,
                               });
                               setLogicAdded(logicAdded == true ? false : true);
                             }
                             }
                      />
                      <span className="slider round"></span>
                      Add logic
                    </label>
                  </div>

                  {questionFormValues.is_logic ?
                    <div className="small-dropdown add-logic-dropdown" style={{width: "200px"}}>
                      <select id="logicOption" name="logicOption" className="pointer form-select"
                              onChange={(item) => {
                                // props.saveJumpTo(index, item.target.value)
                                // setData({logic:item.target.value})
                                setQuestionFormValues({
                                  ...questionFormValues,
                                  logic_option: item.target.value,
                                });
                              }}
                              value={questionFormValues.logic_option}>
                        {/*value={props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : "Question " + props.allJumpTo[index]) : "End survey"}>*/}

                        {dropdownOptions.map(item => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </div>
                    :''
                  }

                  <div className={'cross-q'} style={{marginLeft:'20px'}} onClick={() => {
                    if (cancelQuestion) {
                      cancelQuestion();
                      setQuestionFormValues({
                        question: "",
                        is_optional: true,
                      });
                      setAllOptions([]);
                    }
                  }}>
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="create-projectmodal-form-group">
            <label className="form-label">Your question</label>
            {questionType === 'free' ?
              <textarea
                rows={5}
                className="form-control"
                type={"text"}
                onChange={setQuestion("question")}
                required={true}
                value={questionFormValues.question}
                id="question"

              />
              :
              <input
                className="form-control"
                type={"text"}
                onChange={setQuestion("question")}
                required={true}
                value={questionFormValues.question}
                id="question"

              />
            }


            {questionFormErrors.question &&
            <span style={{color:"red"}}>{questionFormErrors.question}</span>
            }
          </div>

          {/*<div className="create-projectmodal-form-group">*/}
          {/*  <label className="form-label">Text to go in the document</label>*/}
          {/*  <textarea*/}
          {/*    rows={5}*/}
          {/*    className="form-control"*/}
          {/*    type={"text"}*/}
          {/*    onChange={setQuestion("text_document")}*/}
          {/*    required={true}*/}
          {/*    value={questionFormValues.text_document}*/}

          {/*  />*/}

          {/*  {questionFormErrors.text_document &&*/}
          {/*  <span style={{color:"red"}}>{questionFormErrors.text_document}</span>*/}
          {/*  }*/}
          {/*</div>*/}

          <div className="create-projectmodal-form-group">
            <label className="form-label">Text help</label>
            <textarea
              className="form-control"
              type={"text"}
              onChange={setQuestion("text_help")}
              // required={true}
              value={questionFormValues.text_help}
              rows= {5}

            />

            {questionFormErrors.text_help &&
            <span style={{color:"red"}}>{questionFormErrors.text_help}</span>
            }
          </div>
          <div className="button-wrap d-flex justify-content-start">
            <button
              name="Save"
              onClick={handleQuestionSubmit}
              className="btn  submit-btn1"
              //state={questionFormValues.question == "" ? "disabled" : "active"}
            >Save</button>
          </div>


        </div>
      )}
      {(questionType == "singlechoice" ||
        questionType == "multiplechoice") && (
        <>
          <div className={`add-question-wrapper questioninputbox`}>
            <div className={`bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap`}>
              <div className="bd-que-slide-left question-heading-left">
                <div className="db-que-list-wrap survey-question-drop-down">
                  {questionType == "singlechoice" && (
                    <div className="dropdown">
                      Question {questionno} - Single choice

                    </div>
                  )}
                  {questionType == "multiplechoice" &&
                  (
                    <div className="dropdown">
                      Question {questionno} - Multi choice

                    </div>
                  )}
                </div>
              </div>

              <div className={`bd-que-slide-arrow add-logic-survey-option`}>
                <div className="sqa-check-top">
                  <div className="sqa-check-top singlechoice-addlogic">
                    <div className="form-group custom-control d-flex">

                      {/*{questionType == "singlechoice" && (*/}
                        <div className="form-group custom-control mb-0 logicbuttonbox">
                          <label className="switch">
                            <input type="checkbox"
                                   checked={Boolean(questionFormValues.is_logic)}
                                   onChange={(e) => {
                                     setQuestionFormValues({
                                       ...questionFormValues,
                                       is_logic: e.target.checked ? 1 : 0,
                                     });
                                     setLogicAdded(logicAdded == true ? false : true);
                                   }
                                   }
                            />
                            <span className="slider round"></span>
                            Add logic
                          </label>
                        </div>
                      {/*)}*/}

                      {questionFormValues.is_logic && questionType == "multiplechoice"?
                        <div className="small-dropdown add-logic-dropdown" style={{width: "200px"}}>
                          <select id="logicOption" name="logicOption" className="pointer form-select"
                                  onChange={(item) => {
                                    // props.saveJumpTo(index, item.target.value)
                                    // setData({logic:item.target.value})
                                    setQuestionFormValues({
                                      ...questionFormValues,
                                      logic_option: item.target.value,
                                    });
                                  }}
                                  value={questionFormValues.logic_option}>
                            {/*value={props.allJumpTo[index] != "End Survey" ? (props.allJumpTo[index] - props.questionno == 1 ? "Next question" : "Question " + props.allJumpTo[index]) : "End survey"}>*/}

                            {dropdownOptions.map(item => {
                              return (
                                <option value={item.value}>{item.label}</option>
                              );
                            })}
                          </select>
                        </div>
                        :''
                      }

                      <div className={'cross-q'} style={{marginLeft:'20px'}}  onClick={() => {
                        if (cancelQuestion) {
                          cancelQuestion();
                          setQuestionFormValues({
                            question: "",
                            is_optional: true,
                          });
                          setAllOptions([]);
                        }
                      }}>
                        Cancel
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="create-projectmodal-form-group">
              <label htmlFor="inputName5" className="form-label">
                Your question
              </label>
              <input
                className="form-control"
                type={"text"}
                onChange={setQuestion("question")}
                required={true}
                value={questionFormValues.question}
                id={"question"}
                // onKeyDown={(e) => {
                //   if (e.key === 'Enter') {
                //     handleQuestionSubmit();
                //   }
                // }}

              />

              {questionFormErrors.question &&
              <span style={{color:"red"}}>{questionFormErrors.question}</span>
              }

            </div>

            <div className="create-projectmodal-form-group">
              <label className="form-label">Text help</label>
              <textarea
                className="form-control"
                type={"text"}
                onChange={setQuestion("text_help")}
                //required={true}
                value={questionFormValues.text_help}
                id="text_help"
                rows= {5}

              />

              {questionFormErrors.text_help &&
              <span style={{color:"red"}}>{questionFormErrors.text_help}</span>
              }
            </div>
            <div className={`ans-list-wrap`}>
              <p className="small-info-heading">
                Answers
              </p>
              <Options
                isOtherOption={isOtherOption}
                allOptions={allOptions}
                edit={(index) => editOptionHandler(index)}
                close={(index) => closeOptionHandler(index)}
                closeOtherOption={(index) => closeOtherOptionHandler()}
                save={(index, value) => saveOptionHandler(index, value)}
                saveTextDocument={(index, value) => saveAllTextDocument(index, value)}
                saveJumpTo={(index, value) => saveOptionJumpToHandler(index, value)}
                language={language}
                questionType={questionType}
                addOption={(index) => {
                  if (allOptions.length < 200) {
                    allOptions.splice(index + 1, 0, "");
                    setAllOptions([...allOptions]);
                    allJumpTo.splice(index + 1, 0, questionno < questionlist.length ? questionno + 1 : "End Survey");
                    setAllJumpTo([...allJumpTo]);
                  }
                  else {
                    showError("Max two hundred options can be added")

                  }
                }}
                logicAdded={logicAdded}
                jumpToList={jumpToListItems}
                allJumpTo={allJumpTo}
                allTextDocument={allTextDocument}
                questionno={questionno}
                questionsData={questionsData}
              />
            </div>

            <div className=" add-survey-answer-btn">
              {isOtherOption && (
                <>
               Other : 
                 <input placeholder="Enter Other Option" disabled />
                 </>
              )}
                <button 
                disabled={isOtherOption}
                onClick={() => {
                  setIsOtherOption(true);
                  setIsAddOther(true)
                  parentFunction(true)
                }}
                 className="btn submit-btn1">
                  Add other
                </button>
              <button
                className="btn submit-btn1"
                onClick={() => {
                  allOptions.push("");
                  setAllOptions([...allOptions]);
                  allJumpTo.push("End Survey");
                  setAllJumpTo([...allJumpTo]);

                  allTextDocument.push(EditorState.createEmpty());
                  setAllTextDocument([...allTextDocument]);
                }}
              >
                Add answer
              </button>


            </div>
            <div className="button-wrap d-flex justify-content-start">
              <button
                className="btn submit-btn1"
                onClick={handleQuestionSubmit}
                //state={questionFormValues.question == "" ? "disabled" : "active"}
              >Save</button>
            </div>
          </div>


        </>
      )}



    </>
  )
}

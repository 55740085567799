import { createContext, useContext, useState } from "react";
 
const DataContext = createContext(undefined);
 
export const GetDataFromContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("getDataFromContext must be used within a UserProvider");
  }
  return context;
};
 
export const DataProvider = ({ children }) => {
  const [questionAnswers, setQuestionAnswers ] = useState({});
  const [isAddOther, setIsAddOther ] = useState(false);
 
  return (
    <DataContext.Provider value={{ questionAnswers, setQuestionAnswers, isAddOther , setIsAddOther }}>
      {children}
    </DataContext.Provider>
  );
};